import SelectField from "@whitespace/gatsby-plugin-search/src/components/SelectField";
import {
  useSearch,
  useSearchParam,
} from "@whitespace/gatsby-plugin-search/src/hooks";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { useFormikContext, useField } from "formik";
import PropTypes from "prop-types";
import React from "react";

SearchFormMonthSelectField.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string,
};

export default function SearchFormMonthSelectField({
  name,
  label,
  ...restProps
}) {
  const { setFieldValue, submitForm, values } = useFormikContext();
  const { paramType } = useSearchParam(name);
  const searchContext = useSearch();
  const [{ value }] = useField(name);
  const pageContext = usePageContext();
  const contentType = pageContext?.contentType?.name;

  let yearOptions =
    typeof paramType.yearOptions === "function"
      ? paramType.yearOptions({ ...searchContext, values })
      : paramType.yearOptions;

  let monthOptions =
    typeof paramType.monthOptions === "function"
      ? paramType.monthOptions({ ...searchContext, values })
      : paramType.monthOptions;

  if (/^\d{4}$/.test(value)) {
    monthOptions = monthOptions && [
      {
        ...monthOptions.find((option) => option.value === ""),
        value,
      },
      ...monthOptions.filter((option) => option.value.startsWith(value)),
    ];
  } else if (/^\d{4}-\d{2}$/.test(value)) {
    let year = value.substr(0, 4);
    monthOptions = monthOptions && [
      {
        ...monthOptions.find((option) => option.value === ""),
        value: year,
      },
      ...monthOptions.filter((option) => option.value.startsWith(year)),
    ];
    yearOptions = yearOptions?.map?.((option) => ({
      ...option,
      value: option.value === year ? value : option.value,
    }));
  } else {
    monthOptions = null;
  }

  if (!yearOptions || Object.values(yearOptions).length === 0) {
    return null;
  }

  if (contentType === "logotype") {
    return null;
  }

  return (
    <>
      <SelectField
        name={name}
        isMulti={false}
        placeholder={label}
        value={value}
        onChange={(value) => {
          setFieldValue(name, value);
          setTimeout(submitForm, 0);
        }}
        options={yearOptions}
        {...restProps}
      />
      {!!(monthOptions && Object.values(monthOptions).length) &&
        contentType !== "material" && (
          <SelectField
            name={name}
            isMulti={false}
            placeholder={label}
            value={value}
            onChange={(value) => {
              setFieldValue(name, value);
              setTimeout(submitForm, 0);
            }}
            options={monthOptions}
            {...restProps}
          />
        )}
    </>
  );
}
