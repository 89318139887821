import { useID } from "@whitespace/components";
import ToggleButtonGroup from "@whitespace/gatsby-plugin-search/src/components/ToggleButtonGroup";
import {
  useSearch,
  useSearchParam,
} from "@whitespace/gatsby-plugin-search/src/hooks";
import { useFormikContext } from "formik";
import { kebabCase } from "lodash-es";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as styles from "./SearchFormLinksField.module.css";

SearchFormLinksField.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string,
};

export default function SearchFormLinksField({ name, ...restProps }) {
  const generateID = useID();
  const { submitForm } = useFormikContext();
  const { paramType } = useSearchParam(name);
  const searchContext = useSearch();
  const { t } = useTranslation();

  let options =
    typeof paramType.options === "function"
      ? paramType.options({ ...searchContext })
      : paramType.options;

  if (!options || Object.values(options).length === 0) {
    return null;
  }
  return (
    <>
      <div id={generateID(`${kebabCase(name)}-label`)} className={styles.label}>
        {t("sort")}:
      </div>
      <ToggleButtonGroup
        aria-labelledby={generateID(`${kebabCase(name)}-label`)}
        options={options}
        name={name}
        onMouseUp={() => {
          setTimeout(submitForm, 0);
        }}
        itemAppearance="link"
        {...restProps}
      />
    </>
  );
}
