// extracted by mini-css-extract-plugin
export var edit = "DnDMenu-module--edit--pLAQ1";
export var editHidden = "DnDMenu-module--editHidden--dosv0";
export var focusVisible = "DnDMenu-module--focus-visible--aNiJn";
export var header = "DnDMenu-module--header--pZmDf";
export var item = "DnDMenu-module--item--chhWJ";
export var label = "DnDMenu-module--label--zCCBH";
export var link = "DnDMenu-module--link--fZ5TA";
export var linkIcon = "DnDMenu-module--linkIcon--V2fGq";
export var linkLabel = "DnDMenu-module--linkLabel--QQYlS";
export var linkLabelFilled = "DnDMenu-module--linkLabelFilled--V-T0k";
export var linkLabelNotFilled = "DnDMenu-module--linkLabelNotFilled--Rw0ej";
export var list = "DnDMenu-module--list--d8UIG";
export var separator = "DnDMenu-module--separator--nEsKd";
export var toggle = "DnDMenu-module--toggle--ET3Ld";
export var toggleButton = "DnDMenu-module--toggleButton--NAuPK";
export var toggleButtonIsOpen = "DnDMenu-module--toggleButtonIsOpen--d--9e";
export var toggleView = "DnDMenu-module--toggleView--2POXs";