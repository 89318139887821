import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";

import { getSiteMetaData } from "../hooks/siteMeta";

SEO.propTypes = {
  title: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  isFrontPage: PropTypes.bool,
};

export default function SEO({ title, meta = [], isFrontPage }) {
  const {
    i18n: { language: lang },
  } = useTranslation();
  const { title: siteTitle } = getSiteMetaData();

  const titleTemplate = isFrontPage ? siteTitle : `%s - ${siteTitle}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      defaultTitle={siteTitle}
      link={[
        {
          rel: `icon`,
          type: `image/svg+xml`,
          href: `/favicon.svg`,
        },
        {
          rel: `alternate icon`,
          href: `/favicon.ico`,
        },
        {
          rel: `mask-icon`,
          href: `/safari-pinned-tab.svg`,
          color: `#004071`,
        },
      ]}
      meta={[
        {
          name: `referrer`,
          content: `no-referrer`,
        },
        {
          name: `apple-mobile-web-app-capable`,
          content: `yes`,
        },
        {
          name: `apple-mobile-web-app-status-bar-style`,
          content: `white`,
        },
      ]}
    />
  );
}
