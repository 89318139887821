import * as styles from "@whitespace/gatsby-plugin-search/src/components/SearchForm.module.css";
import SearchFormField from "@whitespace/gatsby-plugin-search/src/components/SearchFormField";
import { useSearch } from "@whitespace/gatsby-plugin-search/src/hooks";
import clsx from "clsx";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

SearchForm.propTypes = {
  className: PropTypes.string,
  showHitsTotal: PropTypes.bool,
  gridView: PropTypes.bool,
  setGridView: PropTypes.func,
};

export default function SearchForm({ className, ...props }) {
  const { params, setParams, schema } = useSearch();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={params}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={async (values) => {
        setParams({ ...values, page: null });
      }}
      {...props}
    >
      {({ setFieldValue, submitForm }) => (
        <Form className={clsx(styles.form, className)}>
          <SearchFormField param="query" />
          <SearchFormField param="contentType" />

          <div className={styles.filterContainer}>
            <SearchFormField param="date" />
            <SearchFormField param="category" />
            {/* {meetingsArchive && <SearchFormField param="filter" />} */}
            {!!(
              params.date?.length ||
              // params.filter?.length
              params.category?.length
            ) && (
              <button
                className={styles.clearFilter}
                onClick={() => {
                  setFieldValue("date", null);
                  // setFieldValue("filter", null);
                  setFieldValue("category", null);
                  setTimeout(submitForm, 0);
                }}
                type="button"
              >
                {t("clearFilterLabel")}
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
