// extracted by mini-css-extract-plugin
export var changeView = "SearchForm-module--changeView--ERqbz";
export var clearFilter = "SearchForm-module--clearFilter--HHEP5";
export var filterContainer = "SearchForm-module--filterContainer--X4oNM";
export var filterMulti = "SearchForm-module--filterMulti--hddAb";
export var form = "SearchForm-module--form--Xw5AB";
export var searchHitsLabel = "SearchForm-module--searchHitsLabel--qlAQi";
export var sortContainer = "SearchForm-module--sortContainer--E8b1U";
export var toggleButtonGroupLabel = "SearchForm-module--toggleButtonGroupLabel---w9G6";
export var toggleButtonGroupWrapper = "SearchForm-module--toggleButtonGroupWrapper--eu9Qn";
export var viewIcon = "SearchForm-module--viewIcon--elI0V";