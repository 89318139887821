import { useLocation } from "@gatsbyjs/reach-router";
import ModularityArea from "@municipio/gatsby-theme-basic/src/components/ModularityArea";
import AccessWall from "@municipio/gatsby-theme-intranet/src/components/AccessWall";
import Sidebar from "@municipio/gatsby-theme-intranet/src/components/Sidebar";
import Toolbar from "@municipio/gatsby-theme-intranet/src/components/Toolbar";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import cx from "classnames";
import PropTypes from "prop-types";
import React, { createContext, useState } from "react";

import * as styles from "./SiteLayout.module.css";

SiteLayout.propTypes = { children: PropTypes.node };

export default function SiteLayout({ children }) {
  const [siteContext, setSiteContext] = useState({ menuOpen: false });
  const { menuOpen } = siteContext;

  const { contentNode: { sliderArea } = {} } = usePageContext();
  const location = useLocation();
  const isInnehall = location?.pathname?.startsWith("/innehall/");

  return (
    <SiteLayoutContext.Provider value={[siteContext, setSiteContext]}>
      <AccessWall autoLogin={true}>
        <div className={cx(styles.component, menuOpen && styles.sidebarIsOpen)}>
          <Sidebar />
          <main className={styles.main} id="main">
            <Toolbar />
            <ModularityArea
              area={sliderArea}
              className={cx(styles.sliderArea)}
            />
            <div className={cx(styles.content, isInnehall && styles.innehall)}>
              {children}
            </div>
          </main>
        </div>
      </AccessWall>
    </SiteLayoutContext.Provider>
  );
}

export const SiteLayoutContext = createContext();
