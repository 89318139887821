import { Section } from "@jfrk/react-heading-levels";
import { withComponentDefaults } from "@whitespace/components";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/Article.module.css";
import ArticleBody from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleBody";
import ArticleChildPageNav from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleChildPageNav";
import ArticleFeaturedImage from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleFeaturedImage";
import ArticleMeta from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleMeta";
import ArticlePublishDate from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticlePublishDate";
import ArticleSiblingPageNav from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleSiblingPageNav";
import ArticleTagList from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTagList";
import ArticleTitle from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import {
  usePageContext,
  //  useIsFullWidthPage,
} from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

//import MeetingMaterials from "../../../components/MeetingMaterials";

Article.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default withComponentDefaults(Article, "article");

function Article({ className, styles = defaultStyles, ...restProps }) {
  let {
    contentNode: {
      isFrontPage,
      contentType: {
        node: { name: contentTypeName },
      },
      //      meetingMaterials = {},
    },
  } = usePageContext();

  //let isFullWidthPage = useIsFullWidthPage();

  return (
    <article
      className={clsx(layout.component, layout.componentWidthFull, className)}
      {...restProps}
    >
      <ArticleFeaturedImage />
      <div className={clsx(layout.component, layout.componentWidthFull)}>
        <ArticleTitle />
        <Section>
          <ArticleChildPageNav className={styles.childPages} />
          {contentTypeName === "post" && (
            <ArticlePublishDate className={styles.publishedDate} />
          )}
          <ArticleBody />
          {/* {contentTypeName === "meeting" && (
            <MeetingMaterials meetingMaterials={meetingMaterials} />
          )} */}
          {!isFrontPage && (
            <footer className={styles.footer}>
              <ArticleTagList taxonomy="tags" />
            </footer>
          )}
          {!isFrontPage && <ArticleSiblingPageNav />}
        </Section>
      </div>
    </article>
  );
}
