// import { Icon, RoundIcon } from "gatsby-theme-municipio/src/components/Icon";
// import { FluidImage } from "gatsby-theme-municipio/src/components/Image";
import userContext from "@municipio/gatsby-theme-intranet/src/contexts/userContext";
import { Icon, SkipTo } from "@whitespace/components";
import { Image } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import HeaderLogo from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderLogo";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";

import * as styles from "./Header.module.css";

Header.propTypes = { image: PropTypes.object };

export default function Header({ image, ...restProps }) {
  const { logout } = useContext(userContext);

  const { buttonProps, isOpen } = useDropdownMenu(1);

  delete buttonProps.ref;

  return (
    <header className={clsx(styles.component, styles.sticky)} {...restProps}>
      <div className={clsx(styles.wrapper)}>
        <SkipTo />
        <HeaderLogo className={clsx(styles.logo)} linkTo="/" />
        <div
          className={clsx(styles.dropdownTrigger)}
          {...buttonProps}
          aria-controls="dropdown"
        >
          <div className={clsx(styles.iconWrapper)}>
            {image && image.src ? (
              <Image
                className={clsx(styles.icon)}
                base64={image.base64}
                src={image.src}
                srcSet={image.srcSet}
                srcWebp={image.srcWebp}
                srcSetWebp={image.srcSetWebp}
                width="28"
                height="28"
                alt={image.altText}
              />
            ) : (
              <Icon
                className={clsx(styles.icon)}
                name="avatar"
                // bgColor="var(--logo-background)"
                size="40px"
              />
            )}
          </div>
          <Icon
            name="chevron-down"
            className={clsx(styles.dropdownIcon, "transparent")}
            size="12px"
          />
        </div>
      </div>
      <nav
        id="dropdown"
        className={clsx(styles.dropdown, isOpen && styles.dropdownOpen)}
        aria-label="AnvändarMeny"
      >
        <ul className={clsx(styles.dropdownList)}>
          <li className={clsx(styles.dropdownListItem)}>
            <button
              className={clsx(styles.dropdownLink)}
              onClick={() => logout()}
            >
              Logga ut
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
}
