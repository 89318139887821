import { useFormikContext, useField } from "formik";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import SelectField from "../SelectField";

import * as styles from "./SearchFormCategorySelectField.module.css";

SearchFormCategorySelectField.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string,
};

export default function SearchFormCategorySelectField({ name, ...restProps }) {
  const { setFieldValue, submitForm } = useFormikContext();
  const [{ value }] = useField(name);

  const { categories } = useStaticQuery(graphql`
    query CategoriesQuery {
      wp {
        categories(first: 100000) {
          nodes {
            name
          }
        }
      }
    }
  `).wp;

  let options = categories?.nodes?.map((item) => item.name);

  return (
    <SelectField
      name={name}
      isMulti={false}
      placeholder="Kategori"
      value={value}
      onChange={(value) => {
        setFieldValue(name, value);
        setTimeout(submitForm, 0);
      }}
      options={options}
      className={styles.input}
      {...restProps}
    />
  );
}
