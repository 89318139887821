// extracted by mini-css-extract-plugin
export var bottom = "CookieConsent-module--bottom--+3h02";
export var bottomLeft = "CookieConsent-module--bottomLeft--AwPgF";
export var bottomRight = "CookieConsent-module--bottomRight--3i1p8";
export var button = "CookieConsent-module--button--xZmyp";
export var buttonApprove = "CookieConsent-module--button--approve--FJ9d9";
export var buttonDecline = "CookieConsent-module--button--decline--bYd2a";
export var buttonGroup = "CookieConsent-module--button-group--8WEO0";
export var component = "CookieConsent-module--component--4bGv6";
export var description = "CookieConsent-module--description--EHvjE";
export var more = "CookieConsent-module--more--WdsZZ";
export var title = "CookieConsent-module--title--d2btL";
export var top = "CookieConsent-module--top--yU-NH";
export var topLeft = "CookieConsent-module--topLeft--TDVEm";
export var topRight = "CookieConsent-module--topRight--LHKyl";